<template>
  <v-container class="px-0">
    <section class="col">
      <div class="col pa-0">
        <template v-if="isSpecialGood">
          <div v-if="specialGoodStatus === 'successfully'" class="order__title">
            <p class="p-2">Congratulations! Your order was successful.</p>
          </div>
          <div v-else class="order__title order__title_rejected mb-5">
            <p class="p-2">Oops! There was an error while forming the order. Try again.</p>
          </div>
        </template>

        <div
            v-if="isSpecialGood && specialGoodStatus !== 'successfully'"
            class="order-complete__decline-btn-block"
        >
          <button
              type="button"
              class="order-complete__btn"
              @click="$router.push({ name: 'Registration' })"
          >
            REPEAT PURCHASE
          </button>
        </div>

        <template v-if="isRetailOrder">
          <div v-if="retailOrderDetails.status === 'completed'" class="order__title">
            <p class="p-2">Congratulations! Your order was successful.</p>
          </div>
          <div v-else class="order__title order__title_rejected">
            <p class="p-2">Unfortunately, there was an error in processing the payment. Please try again.</p>
          </div>
        </template>

        <div class="order__detail">
          <div class="order__number">
            <p class="mb-2">Order number</p>
            <span>{{ isSpecialGood ? specialGoodOrderDetails.id : retailOrderDetails?.order_number }}</span>
          </div>
          <div class="order__data">
            <p class="mb-2">Date:</p>
            <span>{{ isSpecialGood ? specialGoodOrderDetails.created_at : retailOrderDetails?.created_at }}</span>
          </div>
          <div class="order__email">
            <p class="mb-2">E-mail:</p>
            <span>{{ isSpecialGood ? specialGoodOrderDetails.email : retailOrderDetails?.customer?.email }}</span>
          </div>
          <div class="order__total">
            <p class="mb-2">Total:</p>
            <span class="notranslate">
              {{ isSpecialGood ? specialGoodOrderDetails.total_price : sumTotal }}
              {{ isSpecialGood ? 'KGS' : retailOrderDetails?.sum_view?.KGS?.currency}}
            </span>
          </div>
        </div>
      </div>

      <div class="order__content">
        <div class="order__inner">
          <h2>Order information</h2>
          <v-expansion-panels
              v-if="!isSpecialGood"
              class="mb-1"
              flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="payment__product mb-0">
                <span>Product</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="item in retailOrderDetails?.order_item"
                  :key="item.id"
                  class="payment__goods order-complete__goods px-0"
                >
                  <span>{{ item?.name?.ru }} × {{ item?.amount }}</span>
                  <span class="price notranslate">
                    {{ item?.sum }}
                    {{
                      retailOrderDetails?.sum_view[
                        retailOrderDetails.currency_code
                      ]?.currency
                    }}
                  </span>
                  <p>Цвет:
                    {{
                      item.variations_data.find((i) => i.name.includes('Цвет'))
                        ?.option.value
                    }}
                  </p>
                  <p>Размер:
                    {{
                      item.variations_data.find((i) =>
                        i.name.includes('Размер')
                      )?.option.value
                    }}
                  </p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels
              v-else
              class="mb-1"
              flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="payment__product mb-0">
                <span>Product</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                    v-for="item in specialOrderProducts"
                    :key="item.id"
                    class="payment__goods order-complete__goods px-0"
                >
                  <span>{{ item.product.name?.ru }} × {{ item.quantity }}</span>
                  <span class="price notranslate">
                    {{ item.price }} KGS
                  </span>
                  <p>Цвет: {{ item.color.name }}</p>
                  <p>Размер: {{ item.dimensions }}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="order__payment-total notranslate">
            <span>Total</span>
            <span v-if="isSpecialGood">
              {{ specialGoodOrderDetails.price }} KGS
            </span>
            <span v-else>
              {{ Number(this.retailOrderDetails?.sum) - Number(this.retailOrderDetails?.discount) }}
              {{
                retailOrderDetails?.sum_view[retailOrderDetails.currency_code]
                    ?.currency
              }}
            </span>
          </div>
          <div v-if="isSpecialGood" class="order__payment-total notranslate mb-1">
            <span>Product status</span>
            <span>
              {{ specialGoodOrderDetails.status.title }}
            </span>
          </div>

          <div v-if="!isSpecialGood" class="order__payment-total notranslate mb-1">
            <span>Delivery</span>
            <span>
              {{ retailOrderDetails?.delivery }}
              {{
                retailOrderDetails?.sum_view[retailOrderDetails.currency_code]
                  ?.currency
              }}
            </span>
          </div>

          <div v-if="isSpecialGood" class="order__payment-total notranslate mb-1">
            <span>ZipCode:</span>
            <span>
              {{ specialGoodOrderDetails.zip_code }}
            </span>
          </div>

          <div v-if="isSpecialGood" class="order__payment-total mb-1">
            <span>Delivery address:</span>
            <span>
              {{ `${specialGoodOrderDetails.country.country} ${specialGoodOrderDetails.region} ${specialGoodOrderDetails.settlement} ${specialGoodOrderDetails.address}` }}
            </span>
          </div>
          <div v-else class="order__payment-total">
            <span>Delivery address:</span>
            <span>
              {{ retailOrderDetails?.customer?.address }}
            </span>
          </div>

        </div>
      </div>
    </section>
  </v-container>
</template>

<script>
import axios from '@/core/services/api.service'

import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState } = createNamespacedHelpers('orderHistory')
const {
  mapActions: mapCartActions,
  mapState: mapCartState,
  mapMutations,
} = createNamespacedHelpers('storeCart')
const { mapActions: ArtisanAction } = createNamespacedHelpers('artisan')

export default {
  data() {
    return {
      order: {},
      specialGoodStatus: '',
      type: '',
      specialOrderProducts: []
    }
  },
  computed: {
    ...mapState(['retailOrderDetails', 'specialGoodOrderDetails']),
    isRetailOrder () {
      if (this.retailOrderDetails) {
        return !!Object.keys(this.retailOrderDetails).length
      }

      return false
    },
    isSpecialGood () {
      return this.type === 'special_goods'
    },
    sumTotal() {
      return Number(this.retailOrderDetails?.sum) - Number(this.retailOrderDetails?.discount) + Number(this.retailOrderDetails?.delivery)
    }
  },
  methods: {
    ...mapActions(['getRetailOrderById', 'getSpecialProductOrderById']),
    ...mapMutations(['CLEAR_CART_ITEMS']),
    ...ArtisanAction(['removeArtisan']),
    getOrderProducts (id) {
      axios.get(`/specialgoods/order/${id}/products`)
      .then((res) => {
        this.specialOrderProducts = res.data.data
      })
    }
  },
  async created() {
    const orderId = this.$route.params.id
    this.type = this.$route.query.type
    const status = this.$route.query.payment_status


    if (status && this.type === 'special_goods') {
      await this.getOrderProducts(orderId)
      await this.getSpecialProductOrderById(orderId)
      this.specialGoodStatus = status

      if(status === 'successfully') {
        this.CLEAR_CART_ITEMS()
        this.removeArtisan()
      }
    } else {
      const data = await this.getRetailOrderById({ orderId })

      if(data.status === "completed") {
        this.CLEAR_CART_ITEMS()
      }
    }
  },
}
</script>

<style scoped lang="scss">
.order-complete {
  &__goods {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  &__decline-btn-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    min-width: 120px;
    padding: 10px 15px;
    background: #cfebcc;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #666666;
    border: none;
    outline: none;
  }
}

</style>
